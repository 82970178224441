/* NotFound.css */
.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .not-found-content {
    padding: 20px;
    max-width: 600px;
  }
  
  .not-found-title {
    font-size: 3rem;
    color: #343a40;
    margin-bottom: 20px;
  }
  
  .not-found-text {
    font-size: 1.25rem;
    color: #6c757d;
    margin-bottom: 30px;
  }
  
  .not-found-link {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    color: #ffffff;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .not-found-link:hover {
    background-color: #0056b3;
  }
  